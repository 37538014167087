.menu-enter {
  opacity: 0;
  transform: translate(-50%, 0);
}

.menu-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 250ms ease;
}

.menu-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.menu-exit-active {
  opacity: 0;
  transform: translate(-50%, 0);
  transition: all 250ms ease;
}

.modal-enter {
  opacity: 0;
  transform: scale(0);
}

.modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 250ms ease;
}

.modal-exit {
  opacity: 1;
  transform: scale(1);
}

.modal-exit-active {
  opacity: 0;
  transform: scale(0);
  transition: all 250ms ease;
}
@import '../../styles/variables.scss';

.button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 24px;
	gap: 4px;

	height: 40px;
	font-size: 16px;
	font-weight: 600;
	border: none;
	outline: none;
	background: $color-white;
	color: $color-black-primary;

	cursor: pointer;

	span {
		padding: 0 4px;
	}

	&:hover,
	&:focus-visible {
		outline: 2px solid $color-white;
	}

	&:active {
		background: $color-gray-light;
	}
}